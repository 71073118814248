import React from 'react'
import { StaticImage } from '../staticSections'
import { Button } from '../../common/button'

export function StaticBlogs({ sliderId, blogsData, headline }) {
  sliderId = 'superslideBlogs' + sliderId
  const sliderHref = '#' + sliderId

  if (blogsData.length > 1) {
    return (
      <section className="bg-img-yellow divider-left">
        <h2 className="text-center">{headline}</h2>
        <div
          id={sliderId}
          className="carousel slide mx-auto mt-4"
          data-ride="carousel"
          data-interval="false"
        >
          <div>
            <div className="carousel-inner">
              <StaticBlogsInner blogsData={blogsData} />
            </div>

            <a
              className="carousel-control-prev mx-4"
              href={sliderHref}
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </a>
            <a
              className="carousel-control-next mx-4"
              href={sliderHref}
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </a>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="bg-img-yellow divider-left">
        <h2 className="text-center">{headline}</h2>
        <div className="mt-4">
          <StaticBlogsInner blogsData={blogsData} />
        </div>
      </section>
    )
  }
}

export function StaticBlogsInner({ blogsData }) {
  return blogsData.map((blog, index) => (
    <div
      key={index}
      className={
        blogsData.length > 1
          ? index === 0
            ? 'carousel-item active'
            : 'carousel-item'
          : ''
      }
    >
      <StaticBlogHeader blog={blog} showImageLeft={false} />
    </div>
  ))
}

export function StaticBlogHeader({ blog, showImageLeft }) {
  const imageDiv = (
    <div className="col-6 text-center">
      {blog.header && blog.header.image ? (
        <StaticImage image={blog.header.image} />
      ) : (
        <div />
      )}
    </div>
  )
  return (
    <>
      <div className="row align-items-center">
        <div className="col-10 offset-1">
          <div className="row align-items-center">
            {showImageLeft && imageDiv}

            <div className="col-6 text-center">
              <h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blog.header && blog.header.title,
                  }}
                />
              </h1>
              <h3>
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: blog.header && blog.header.subtitle,
                  }}
                />
              </h3>
            </div>

            {!showImageLeft && imageDiv}
          </div>
        </div>

        <div className="col-10 offset-1 text-center">
          <Button color="dark-blue" href={blog.slug}>
            Meine Top 10
          </Button>
        </div>
      </div>
    </>
  )
}
