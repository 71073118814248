import * as React from 'react'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { CMSWrapper } from '../components/cmsWrapper'
import { StaticBlogHeader } from '../components/blogs/staticBlogs'
import { Footer } from '../common/footer'

export default function Blogs({ pageContext }) {
  const [searchInput, setSearchInput] = React.useState()
  const searchResult = React.useMemo(() => {
    return (
      <>
        {pageContext.blogs
          .filter(
            (blog) =>
              !searchInput ||
              searchInput.length === 0 ||
              isMatching(blog, searchInput)
          )
          .map((blog, index) => (
            <section key={blog._id} className="bg-blue divider-left">
              <div className="mt-4">
                <StaticBlogHeader blog={blog} showImageLeft={index % 2 === 0} />
              </div>
            </section>
          ))}
      </>
    )
  }, [searchInput, pageContext.blogs])

  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Blog" />
      <main>
        <Navigation />
        <header className="bg-img-yellow text-center divider-left mb-0">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Jukebox Blog</h2>
              <p>
                Hier findest Du spannende Geschichten und aktuelle Neuigkeiten
                aus unseren Stationen.
              </p>
              <p>
                Schaue regelmäßig nach den neuesten Beiträgen oder durchsuche
                alle Einträge nach Themen, die dich besonders interessieren.
              </p>
            </div>
          </div>
        </header>
        <section className="mt-0 pb-md-0 pb-4">
          <div className="row align-items-center">
            <input
              className="col-md-5 offset-md-2 col-12"
              type="search"
              name="blogSearch"
              placeholder="Blogbeiträge durchsuchen..."
              onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
            />
            <a
              href="#blogs"
              className="col-md-2 offset-md-1 button bg-blue col-6 offset-3 text-center"
            >
              Finden
            </a>
          </div>
        </section>
        {searchResult}
        <Footer />
      </main>
    </CMSWrapper>
  )
}

const isMatching = (blog, searchInput) => {
  return (
    blog.header.title.toLowerCase().includes(searchInput) ||
    (blog.header.subtitle &&
      blog.header.subtitle.toLowerCase().includes(searchInput))
  )
}
